// @ts-check
const { contentfulPlugin } = require("@shopstory/core/contentful")
const { apolloClient } = require("./contentful/apolloClient")
const {
  GET_COLLECTIONS_BY_ID,
  GET_PRODUCTS_BY_IDS_QUERY,
} = require("./contentful/queries")
const { generateGatsbyImageData } = require("./gatsby/imageUtils")
const { contentfulParams } = require("./contentfulParams")
const {
  GET_COLLECTIONS_FOR_WIDGET_BY_QUERY,
  GET_COLLECTION_FOR_WIDGET_BY_ID,
  GET_PRODUCTS_FOR_WIDGET_BY_QUERY,
  GET_PRODUCT_FOR_WIDGET_BY_ID,
} = require("./contentful/queries")

const isHighlightVariant = isVariantOfType("highlight")

/** @type {import('@shopstory/core').Config}  */
const config = {
  plugins: [contentfulPlugin(contentfulParams)],
  fonts: [
    {
      id: "heading_1",
      label: "Heading 1",
      value: {
        fontSize: 32,
        fontFamily: "Unica77, Helvetica, Arial, Sans-Serif",
        fontWeight: 400,
        lineHeight: 1.2,
        "@sm": {
          fontSize: 28,
        },
      },
      mapTo: "$heading1",
    },
    {
      id: "heading_2",
      label: "Heading 2",
      value: {
        fontSize: 28,
        fontFamily: "Unica77, Helvetica, Arial, Sans-Serif",
        fontWeight: 400,
        lineHeight: 1.2,
        "@sm": {
          fontSize: 24,
        },
      },
      mapTo: "$heading2",
    },
    {
      id: "heading_3",
      label: "Heading 3",
      value: {
        fontSize: 24,
        fontFamily: "Unica77, Helvetica, Arial, Sans-Serif",
        fontWeight: 400,
        lineHeight: 1.2,
        "@sm": {
          fontSize: 20,
        },
      },
      mapTo: "$heading3",
    },
    {
      id: "heading_4",
      label: "Heading 4",
      value: {
        fontSize: 18,
        fontFamily: "Unica77, Helvetica, Arial, Sans-Serif",
        fontWeight: 400,
        lineHeight: 1.2,
        "@sm": {
          fontSize: 16,
        },
      },
      mapTo: "$heading4",
    },
    {
      id: "heading_5",
      label: "Mobile Heading",
      value: {
        fontSize: 20,
        fontFamily: "Unica77, Helvetica, Arial, Sans-Serif",
        fontWeight: 400,
        lineHeight: 1.2,
      },
    },
    {
      id: "body3",
      label: "Body",
      value: {
        fontSize: 14,
        fontFamily: "Unica77, Helvetica, Arial, Sans-Serif",
        fontWeight: 400,
        lineHeight: 1.5,
      },
      mapTo: ["$body", "$body.bold", "$body2", "$body2.bold"],
    },
    {
      id: "body_italic",
      label: "Body Italic",
      value: {
        fontSize: 14,
        fontFamily: "Unica77, Helvetica, Arial, Sans-Serif",
        fontWeight: 400,
        lineHeight: 1.5,
        fontStyle: "italic",
      },
    },
  ],

  colors: [
    {
      id: "almostWhite",
      value: "#f6f6f6",
      mapTo: ["$light", "$backgroundLight"],
    },
    {
      id: "black",
      value: "#181818",
      mapTo: "$dark",
    },
    {
      id: "dark",
      value: "#454545",
      mapTo: "$backgroundDark",
    },
    {
      id: "darkest",
      value: "#181818",
    },
    {
      id: "primary",
      value: "#181818",
    },
    {
      id: "white",
      value: "#ffffff",
    },
    {
      id: "red",
      value: "#ce4646",
    },
    {
      id: "green",
      value: "#4c9a62",
    },
    {
      id: "grayscale-100",
      value: "#f6f6f6",
    },
    {
      id: "grayscale-200",
      value: "#ececec",
    },
    {
      id: "grayscale-300",
      value: "#e3e3e3",
    },
    {
      id: "grayscale-400",
      value: "#c4c4c4",
    },
    {
      id: "grayscale-500",
      value: "#9e9e9d",
    },
    {
      id: "grayscale-600",
      value: "#707070",
    },
    {
      id: "grayscale-700",
      value: "#454545",
    },
  ],

  space: [
    {
      id: "containerMargin.default",
      label: "Default",
      value: {
        "@initial": 16,
      },
    },
    {
      id: "containerMargin.small",
      label: "Small",
      value: {
        "@initial": 8,
      },
    },
    {
      id: "containerMargin.medium",
      label: "Medium",
      value: {
        "@xs": 16,
        "@sm": 38,
        "@md": 46,
        "@lg": 64,
        "@xl": 64,
        "@2xl": 96,
      },
    },
    {
      id: "containerMargin.large",
      label: "Large",
      value: {
        "@xs": 20,
        "@sm": 20,
        "@md": 60,
        "@lg": 80,
        "@xl": 108,
        "@2xl": 152,
      },
    },
  ],

  aspectRatios: [
    {
      id: "$gridMainObjectDefault",
      value: "4:5",
    },
    {
      id: "4:5",
      value: "4:5",
    },
    {
      id: "5:4",
      value: "5:4",
    },
  ],

  mainGrid: {
    containerMargin: 0,
    horizontalGap: 1,
    verticalGap: 0,
    numberOfItemsInRow: {
      "@xs": "2",
      "@sm": "2",
      "@md": "4",
    },
  },

  grids: {
    collection: {
      numberOfItemsInRow: {
        "@xs": "2",
        "@sm": "2",
        "@md": "3",
      },
    },
  },

  containerWidths: [{ id: "896px", value: 896 }],

  buttons: [
    {
      id: "Button",
      schema: [
        {
          prop: "variant",
          type: "select",
          options: ["primary", "secondary", "ghost"],
          label: "Variant",
        },
        {
          prop: "disabled",
          type: "boolean",
          label: "Disabled",
        },
      ],
    },
  ],

  components: [
    {
      id: "ProductCard",
      type: "card",
      schema: [
        {
          prop: "product",
          type: "resource",
          resourceType: "product",
          label: "Product",
        },
        {
          prop: "variant",
          type: "select",
          options: ["default", "highlight", "tile", "custom"],
          label: "Variant",
        },
        {
          prop: "showName",
          type: "boolean",
          label: "Show name",
          visible: (values) => isVariantOfType("default")(values),
        },
        {
          prop: "showProductTitle",
          type: "boolean",
          label: "Show title",
          visible: isHighlightVariant,
        },
        {
          prop: "showProductColor",
          type: "boolean",
          label: "Show color",
          visible: isHighlightVariant,
        },
        {
          prop: "showProductPrice",
          type: "boolean",
          label: "Show price",
          visible: isHighlightVariant,
        },
        {
          prop: "showProductTeaser",
          type: "boolean",
          label: "Show description",
          visible: isHighlightVariant,
        },
        {
          prop: "image",
          type: "resource",
          resourceType: "contentful-asset",
          label: "Image",
          optional: true,
          transform: (asset) => {
            const file = asset.fields.file
            const title = asset.fields.title

            const width = parseInt(file.details.image.width)
            const height = parseInt(file.details.image.height)

            return {
              alt: title,
              url: `https:${file.url}`,
              aspectRatio: width / height,
              srcset: [
                {
                  url: `https:${file.url}`,
                  w: width,
                  h: height,
                },
              ],
              mimeType: file.contentType,
            }
          },
        },
        {
          prop: "imageOnHover",
          type: "resource",
          resourceType: "contentful-asset",
          label: "Image on Hover",
          optional: true,
          transform: (asset) => {
            const file = asset.fields.file
            const title = asset.fields.title

            const width = parseInt(file.details.image.width)
            const height = parseInt(file.details.image.height)

            return {
              alt: title,
              url: `https:${file.url}`,
              aspectRatio: width / height,
              srcset: [
                {
                  url: `https:${file.url}`,
                  w: width,
                  h: height,
                },
              ],
              mimeType: file.contentType,
            }
          },
        },
      ],
    },
    {
      id: "ProductTile",
      type: "card",
      schema: [
        {
          prop: "image",
          type: "resource",
          resourceType: "contentful-asset",
          label: "Image",
          transform: (asset) => {
            const file = asset.fields.file
            const title = asset.fields.title

            const width = parseInt(file.details.image.width)
            const height = parseInt(file.details.image.height)

            return {
              alt: title,
              url: `https:${file.url}`,
              aspectRatio: width / height,
              srcset: [
                {
                  url: `https:${file.url}`,
                  w: width,
                  h: height,
                },
              ],
              mimeType: file.contentType,
            }
          },
        },
        {
          prop: "mode",
          type: "select",
          options: [
            { value: "productReference", label: "Product reference" },
            { value: "customLink", label: "Custom link" },
          ],
          label: "Mode",
        },
        {
          prop: "product",
          type: "resource",
          resourceType: "product",
          label: "Product",
          optional: true,
          visible: ({ mode }) => mode === "productReference",
        },
        {
          prop: "linkTo",
          type: "string",
          label: "Link to",
          defaultValue: "",
          visible: ({ mode }) => mode === "customLink",
        },
        {
          prop: "title",
          type: "text",
          label: "Title",
        },
        {
          prop: "hideTitle",
          type: "boolean",
          label: "Hide title",
        },
        {
          prop: "imageText",
          type: "text",
          label: "Image text",
          defaultValue: "",
        },
        {
          prop: "bannerId",
          type: "string",
          label: "Banner ID",
          defaultValue: "",
        },
      ],
    },
  ],

  links: [
    {
      id: "CollectionLink",
      label: "Collection Link",
      schema: [
        {
          prop: "collection",
          type: "resource",
          resourceType: "collection",
          label: "Collection",
        },
      ],
    },
    {
      id: "ProductLink",
      label: "Product link",
      schema: [
        {
          prop: "product",
          type: "resource",
          resourceType: "product",
          label: "Product",
        },
      ],
    },
    {
      id: "GatsbyLink",
      label: "Internal link",
      schema: [
        {
          prop: "pagePath",
          type: "string",
          label: "Page path",
          defaultValue: "/",
        },
      ],
    },
  ],

  //Here we define custom types that can be used as schema of your custom components
  resourceTypes: {
    product: {
      widget: {
        type: "item-picker",
        getItems: async (query) => {
          const { data } = await apolloClient.query({
            query: GET_PRODUCTS_FOR_WIDGET_BY_QUERY,
            variables: {
              query,
            },
            errorPolicy: "all",
          })

          const items = []

          data.productCollection.items.forEach((product) => {
            items.push({
              id: product.objectId,
              title: product.title,
              thumbnail: product.thumbnail.url,
            })
          })

          data.beddingBundleCollection.items.forEach((beddingBundle) => {
            items.push({
              id: beddingBundle.sys.id,
              title: beddingBundle.name,
              thumbnail: beddingBundle.duvetProduct?.thumbnail.url,
            })
          })

          return items
        },
        getItemById: async (id) => {
          const { data } = await apolloClient.query({
            query: GET_PRODUCT_FOR_WIDGET_BY_ID,
            variables: {
              id,
            },
            errorPolicy: "all",
          })

          if (data.productCollection.items.length > 0) {
            const product = data.productCollection.items[0]

            return {
              id: product.objectId,
              title: product.title,
              thumbnail: product.thumbnail.url,
            }
          }

          if (data.beddingBundleCollection.items.length > 0) {
            const beddingBundle = data.beddingBundleCollection.items[0]

            return {
              id: beddingBundle.sys.id,
              title: beddingBundle.name,
              thumbnail: beddingBundle.duvetProduct?.thumbnail.url,
            }
          }

          throw new Error(
            `Couldn't find any product or bedding bundle matching id ${id}`
          )
        },
      },
      fetch: async function (resources) {
        const ids = resources.map((resource) => resource.id)

        const { data } = await apolloClient.query({
          query: GET_PRODUCTS_BY_IDS_QUERY,
          variables: {
            ids,
            limit: ids.length,
          },
        })

        const fetchProductsResponse = []

        for (const product of data.productCollection.items) {
          const relatedResourceInput = resources.find(
            (resource) => resource.id === product.objectId
          )

          if (!relatedResourceInput) {
            throw new Error(
              `Couldn't find related resource input for below "product":\n\n${JSON.stringify(
                product,
                null,
                2
              )}`
            )
          }

          fetchProductsResponse.push({
            ...relatedResourceInput,
            value: mapProductToProductCardProduct(product),
          })
        }

        for (const beddingBundle of data.beddingBundleCollection.items) {
          const relatedResourceInput = resources.find(
            (resource) => resource.id === beddingBundle.sys.id
          )

          if (!relatedResourceInput) {
            throw new Error(
              `Couldn't find related resource input for below "beddingBundle":\n\n${JSON.stringify(
                beddingBundle,
                null,
                2
              )}`
            )
          }

          const product = getDefaultBeddingBundleProduct(beddingBundle)

          fetchProductsResponse.push({
            ...relatedResourceInput,
            value: mapProductToProductCardProduct(product),
          })
        }

        return fetchProductsResponse
      },
    },
    collection: {
      widget: {
        type: "item-picker",
        getItemById: async (id) => {
          const { data } = await apolloClient.query({
            query: GET_COLLECTION_FOR_WIDGET_BY_ID,
            variables: {
              id,
            },
            errorPolicy: "all",
          })

          if (data.collectionCollection.items.length > 0) {
            const collection = data.collectionCollection.items[0]

            return {
              id: collection.sys.id,
              title: collection.name,
              thumbnail: collection.metaImage?.url,
            }
          }

          throw new Error(`Couldn't find any collection matching id ${id}`)
        },
        getItems: async (query) => {
          const { data } = await apolloClient.query({
            query: GET_COLLECTIONS_FOR_WIDGET_BY_QUERY,
            variables: {
              query,
            },
            errorPolicy: "all",
          })

          const items = []

          data.collectionCollection.items.forEach((collection) => {
            items.push({
              id: collection.sys.id,
              title: collection.name,
              thumbnail: collection.metaImage?.url,
            })
          })

          return items
        },
      },
      fetch: async function (resources) {
        const ids = resources.map((resource) => resource.id)

        const { data } = await apolloClient.query({
          query: GET_COLLECTIONS_BY_ID,
          variables: {
            ids,
            limit: ids.length,
          },
        })

        const collectionsResponse = []

        for (const collection of data.collectionCollection.items) {
          const relatedResourceInput = resources.find(
            (resource) => resource.id === collection.sys.id
          )

          if (!relatedResourceInput) {
            throw new Error(
              `Couldn't find related resource input for below "collection":\n\n${JSON.stringify(
                collection,
                null,
                2
              )}`
            )
          }

          collectionsResponse.push({
            ...relatedResourceInput,
            value: {
              slug: collection.slug,
              category: collection.linkedFrom.categoryCollection.items.map(
                (category) => {
                  return {
                    slug: category.slug,
                  }
                }
              ),
              internal: { type: prefixType(collection.__typename) },
            },
          })
        }

        return collectionsResponse
      },
    },
  },
  // This feature is experimental and for now it requires manual job
  // In the future, templates will be saved from within editor and stored on the Shopstory servers
  unstable_templates: [
    {
      id: "$StandardActionStyles.default",
      type: "",
      config: {
        _template: "$StandardActionStyles",
        isColorOverwriteEnabled: false,
        color: {
          $res: true,
          xl: {
            ref: "black",
          },
        },
        hoverColor: {
          $res: true,
          xl: {
            ref: "black",
          },
        },
        isHoverColorEnabled: false,
        underline: "enabled",
        opacity: "1",
        hoverOpacity: "1",
        hoverOpacityAnimationDuration: "100ms",
      },
      isDefaultTextModifier: true,
    },

    {
      label: "Button Primary",
      mapTo: ["buttonDark"],
      config: {
        _template: "Button",
        variant: "primary",
      },
    },

    {
      label: "Banner Card",
      config: {
        _id: "8ac91413-6c89-4f36-bfd6-629a2aaf0f47",
        _template: "$BannerCard2",
        traceId: "$BannerCard2-9ecb13b3",
        _itemProps: {
          $GridCard: {
            Cards: {
              itemSize: {
                $res: true,
                xl: "1x1",
              },
              verticalAlign: {
                $res: true,
                xl: "auto",
              },
            },
          },
        },
        cornerRadius: {
          $res: true,
          xl: "0",
        },
        Card1: [
          {
            _id: "e98a30e0-cedf-4e74-a807-c40ec66b47d3",
            _template: "$BasicCard",
            traceId: "$BasicCard-ac0f2380",
            size: {
              $res: true,
              xl: {
                value: "fit-content",
              },
            },
            cornerRadius: {
              $res: true,
              xl: "0",
            },
            Background: [],
            enableContent: true,
            position: {
              $res: true,
              xl: "bottom-left",
            },
            paddingLeft: {
              $res: true,
              xl: {
                value: "24px",
                ref: "24",
              },
            },
            paddingRight: {
              $res: true,
              xl: {
                value: "24px",
                ref: "24",
              },
            },
            paddingTop: {
              $res: true,
              xl: {
                value: "16px",
                ref: "16",
              },
            },
            paddingBottom: {
              $res: true,
              xl: {
                value: "16px",
                ref: "16",
              },
            },
            paddingLeftExternal: {
              $res: true,
              xl: {
                value: "0px",
                ref: "0",
              },
            },
            paddingRightExternal: {
              $res: true,
              xl: {
                value: "0px",
                ref: "0",
              },
            },
            paddingTopExternal: {
              $res: true,
              xl: {
                value: "0px",
                ref: "0",
              },
            },
            paddingBottomExternal: {
              $res: true,
              xl: {
                value: "0px",
                ref: "0",
              },
            },
            edgeMarginProtection: {
              $res: true,
              xl: true,
            },
            Stack: [
              {
                _id: "0555d819-8363-4b93-82d1-8f63ce76c4f5",
                _template: "$stack",
                traceId: "$stack-5d5d0481",
                Items: [
                  {
                    _id: "158df037-34c7-4b05-b82d-203e4b8b2e08",
                    _template: "$richText",
                    traceId: "$richText-fcb81859",
                    _itemProps: {
                      $stack: {
                        Items: {
                          width: {
                            $res: true,
                            xl: "512px",
                          },
                          marginBottom: {
                            $res: true,
                            xl: {
                              value: "16px",
                              ref: "16",
                            },
                          },
                          align: {
                            $res: true,
                            xl: "left",
                          },
                        },
                      },
                    },
                    elements: {
                      en: [
                        {
                          _id: "bce67989-501b-4bcf-9026-ff56fa2ca213",
                          _template: "$richTextBlockElement",
                          traceId: "$richTextBlockElement-fa0a4bdc",
                          type: "paragraph",
                          elements: [
                            {
                              _id: "3a5e293d-1a0a-44f8-9177-a37321b1a78d",
                              _template: "$richTextLineElement",
                              traceId: "$richTextLineElement-1ae0352b",
                              elements: [
                                {
                                  _id: "443da0b1-dd4b-4297-abf3-f583b425dc8e",
                                  _template: "$richTextPart",
                                  traceId: "$richTextPart-3c839203",
                                  value: "Lorem ipsum dolor sit amet",
                                  font: {
                                    $res: true,
                                    xl: {
                                      ref: "body3",
                                      value: {
                                        $res: true,
                                        "2xl": {
                                          fontSize: 13,
                                          fontFamily:
                                            "Unica77, Helvetica, Arial, Sans-Serif",
                                          fontWeight: 400,
                                          lineHeight: 1.5,
                                        },
                                      },
                                    },
                                  },
                                  color: {
                                    $res: true,
                                    xl: {
                                      value: "#181818",
                                      ref: "black",
                                    },
                                  },
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                    accessibilityRole: "div",
                    isListStyleAuto: true,
                    mainFont: {
                      $res: true,
                      xl: {
                        value: {
                          fontSize: 32,
                          fontFamily: "sans-serif",
                        },
                        ref: "$heading",
                      },
                    },
                    mainColor: {
                      $res: true,
                      xl: {
                        value: "#181818",
                        ref: "black",
                      },
                    },
                  },
                ],
              },
            ],
          },
        ],
        Card2: [
          {
            _id: "aff353ef-5e35-48ac-b1d1-53021ffe2fca",
            _template: "$BasicCardBackground",
            traceId: "$BasicCardBackground-5c8fad9e",
            size: {
              $res: true,
              xl: {
                ref: "4:5",
                value: "4:5",
              },
            },
            cornerRadius: {
              $res: true,
              xl: "0",
            },
            Background: [
              {
                _id: "7313de62-1493-48af-a843-60fb804ab41c",
                _template: "$image",
                traceId: "$image-15133d48",
                image: {
                  $res: true,
                  xl: {
                    id: null,
                  },
                },
                aspectRatio: {
                  $res: true,
                  xl: {
                    value: "16:9",
                    ref: "$landscape",
                  },
                },
                action: [],
                traceImpressions: false,
                traceClicks: false,
              },
            ],
            enableContent: false,
            position: {
              $res: true,
              xl: "top-left",
            },
            paddingLeft: {
              $res: true,
              xl: {
                value: "24px",
                ref: "24",
              },
            },
            paddingRight: {
              $res: true,
              xl: {
                value: "24px",
                ref: "24",
              },
            },
            paddingTop: {
              $res: true,
              xl: {
                value: "24px",
                ref: "24",
              },
            },
            paddingBottom: {
              $res: true,
              xl: {
                value: "24px",
                ref: "24",
              },
            },
            paddingLeftExternal: {
              $res: true,
              xl: {
                value: "0px",
                ref: "0",
              },
            },
            paddingRightExternal: {
              $res: true,
              xl: {
                value: "0px",
                ref: "0",
              },
            },
            paddingTopExternal: {
              $res: true,
              xl: {
                value: "0px",
                ref: "0",
              },
            },
            paddingBottomExternal: {
              $res: true,
              xl: {
                value: "0px",
                ref: "0",
              },
            },
            edgeMarginProtection: {
              $res: true,
              xl: true,
            },
            Stack: [
              {
                _id: "d40eb0bf-8f05-4df3-aba9-8daa6c95f718",
                _template: "$stack",
                traceId: "$stack-a1d1f842",
                Items: [],
              },
            ],
          },
        ],
        mode: {
          $res: true,
          xl: "top",
        },
        sideModeWidth: {
          $res: true,
          xl: "50%",
        },
        backgroundModePosition: {
          $res: true,
          xl: "bottom-center",
        },
        backgroundModePaddingLeft: {
          $res: true,
          xl: {
            value: "24px",
            ref: "24",
          },
        },
        backgroundModePaddingRight: {
          $res: true,
          xl: {
            value: "24px",
            ref: "24",
          },
        },
        backgroundModePaddingTop: {
          $res: true,
          xl: {
            value: "24px",
            ref: "24",
          },
        },
        backgroundModePaddingBottom: {
          $res: true,
          xl: {
            value: "24px",
            ref: "24",
          },
        },
        backgroundModeEdgeMarginProtection: {
          $res: true,
          xl: true,
        },
        action: [],
        traceImpressions: false,
        traceClicks: false,
      },
      type: "item",
    },
  ],
  devices: {
    lg: {
      hidden: false,
    },
  },
}

module.exports = {
  config,
}

function isVariantOfType(variant) {
  return (values) => {
    return values.variant === variant
  }
}

function mapProductToProductCardProduct(product) {
  const productCardProduct = {
    color: product.color,
    handle: product.handle,
    title: product.title,
    internal: {
      type: prefixType(product.__typename),
    },
    collection:
      product.linkedFrom.collectionCollection.items.length === 0
        ? undefined
        : product.linkedFrom.collectionCollection.items.map((collection) => {
            return {
              name: collection.name,
              slug: collection.slug,
              ...(collection.teaser && {
                teaser: {
                  childMarkdownRemark: {
                    html: collection.teaser,
                  },
                },
              }),
              category: collection.linkedFrom.categoryCollection.items,
              productPageType: collection.productPageType,
            }
          }),
    bedding_bundle:
      product.linkedFrom.beddingBundleCollection.items.length === 0
        ? undefined
        : product.linkedFrom.beddingBundleCollection.items.map(
            (beddingBundle) => {
              const collection =
                beddingBundle.linkedFrom.collectionCollection.items?.[0]

              return {
                collection: [
                  {
                    slug: collection?.slug,
                    category: [
                      {
                        slug: collection?.linkedFrom?.categoryCollection
                          ?.items?.[0]?.slug,
                      },
                    ],
                  },
                ],
              }
            }
          ),
    variants: product.variantsCollection.items,
    sku: product.sku,
    imagePacks: product.imagePacksCollection.items.map((imagePack) => {
      return {
        isFemale: imagePack.isFemale,
        featuredImage: {
          title: imagePack.featuredImage.title,
          gatsbyImageData: generateGatsbyImageData({
            imageUrl: imagePack.featuredImage.url,
            width: imagePack.featuredImage.width,
            height: imagePack.featuredImage.height,
          }),
        },
        alternateImage: {
          title: imagePack.alternateImage.title,
          gatsbyImageData: generateGatsbyImageData({
            imageUrl: imagePack.alternateImage.url,
            width: imagePack.alternateImage.width,
            height: imagePack.alternateImage.height,
          }),
        },
      }
    }),
  }

  return productCardProduct
}

function prefixType(type) {
  return `Contentful${type}`
}

function getDefaultBeddingBundleProduct(beddingBundle) {
  let product = {}

  if (beddingBundle?.duvetProduct) {
    product = beddingBundle.duvetProduct
  } else if (beddingBundle?.pillowProduct) {
    product = beddingBundle.pillowProduct
  } else if (beddingBundle?.flatSheetProduct) {
    product = beddingBundle.flatSheetProduct
  } else if (beddingBundle?.shortSleeveProduct) {
    product = beddingBundle.shortSleeveProduct
  }

  return product
}
